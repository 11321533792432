import Meta from 'components/public/Meta';
import ReloveHeader from 'components/public/ReloveHeader';
import ReloveFooter from 'components/public/ReloveFooter';
import HexImages from 'components/public/HexImages';

import { CHAT_SUPPORT } from '../relove-lib/utils/constants';
import prisma from '../relove-lib/utils/prisma';
import { fetch_listings } from 'logic/storefront_fetch';

import styles from 'components/public/Home.module.css';
import * as buttonStyles from 'components/buttons/Button.module.css';
import SectionCarousel from 'components/public/SectionCarousel.js';

import FaqBuyer from 'components/sell/FaqBuyer';
import FaqSeller from 'components/sell/FaqSeller';
import FaqMerchant from 'components/sell/FaqMerchant';

import { Accordion } from 'react-bootstrap';

import axios from 'axios';
import Typed from 'typed.js';

import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';

const Index = ({ formattedListings }) => {
    const [animateArrow, setAnimateArrow] = useState(false);

    // Create reference to store the Typed instance itself
    const typed = useRef(null);
    const arrowRef = useRef(null);
    const typedEl = useRef(null);

    useEffect(() => {
        const arrowObserver = new window.IntersectionObserver(
            (e) => {
                if (e[0].isIntersecting && !animateArrow) setAnimateArrow(true);
                // else setAnimateArrow(false);
            },
            { threshold: 0.5, rootMargin: '200px 0px 0px 0px' }
        );

        arrowObserver.observe(arrowRef.current);

        // elRef refers to the <h1> rendered below
        typed.current = new Typed(typedEl.current, {
            strings: [
                'go circular',
                'build customer loyalty',
                'liquidate damaged inventory',
                'acquire new customers'
            ],
            typeSpeed: 40,
            backSpeed: 20,
            loop: true,
            backDelay: 2000,
            startDelay: 1500
        });

        return () => {
            arrowObserver.disconnect(arrowRef.current);
            typed.current.destroy();
        };
    }, []);

    return (
        <div style={{ maxWidth: '100%', padding: 'unset' }}>
            {/* Hero Section */}
            <div className={styles.heroSection + ' homePageCommonContainer'}>
                <div
                    style={{
                        maxWidth: '60%',
                        alignSelf: 'center'
                    }}
                >
                    <h1>
                        Resale technology for brands to <span ref={typedEl}>go circular</span>
                    </h1>
                </div>

                <HexImages />
            </div>

            {/* relove/rescue parallax */}
            <div className={styles.productContainer}>
                <div
                    style={{
                        background: '#ECF2EF',
                        padding: '5% 5%'
                    }}
                >
                    <h2>Relove</h2>
                    <h3>A Peer to Peer resale marketplace for your customers</h3>
                    <img className={styles.productImage} src="/img/home/illustrations/relove.png" />
                </div>
                <div
                    style={{
                        background: '#FFEFD0',
                        padding: '5% 5%'
                    }}
                >
                    <h2>Retake</h2>
                    <h3>Bespoke takeback programs designed to fit your brands needs</h3>
                    <img className={styles.productImage} src="/img/home/illustrations/retake.png" />
                </div>
                <div
                    style={{
                        background: '#F5F4F1',
                        padding: '5% 5%'
                    }}
                >
                    <h2>Rescue</h2>
                    <h3>Liquidate damaged inventory from your warehouse</h3>
                    <img
                        className={styles.productImage}
                        src="/img/home/illustrations/rescue-2.png"
                    />
                </div>
            </div>

            {/* Brand list */}
            <div className={styles.brandsSection + ' homePageCommonContainer'}>
                <h3>50+ Brands that trust us</h3>
                <h2>
                    Good brands design <br />
                    Great ones think of it's lifecycle
                </h2>

                <div className={styles.logoContainer}>
                    <div>
                        <img src="/img/home/brands/chumbak.jpg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/Snitch.jpg" />
                    </div>
                    <div style={{ gridColumnEnd: 'span 2' }}>
                        <img src="/img/home/brands/summer_house.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/jodi.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/fancy_pastels.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/summer_somewhere.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/NN_web_log03.webp" />
                    </div>
                    <div>
                        <img src="/img/home/brands/suta.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/bunaai.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/okhai.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/aachho.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/saaki.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/why_so_blue.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/soul.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/tura_turi.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/ltwt.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/miko_lolo.svg" />
                    </div>
                    <div>
                        <img src="/img/home/brands/nete.svg" />
                    </div>
                </div>
                {/* <button type="button" className={buttonStyles.secondaryButtonSmall}>
                    View All brands
                </button> */}
            </div>

            {/* HIW */}
            <div id="hiwSection" className={styles.hiwContainer + ' homePageCommonContainer'}>
                <img className={styles.bgPink} src="/img/hex-bg-pink.svg" />
                <div
                    style={{
                        marginTop: '10%',
                        textAlign: 'center'
                    }}
                >
                    <h2 style={{ whiteSpace: 'nowrap' }}>The new ecommerce</h2>
                    <h2
                        style={{
                            color: '#4e7f5e',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        <b className={styles.nbsp}>
                            &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        </b>
                        growth formula &nbsp;
                        <img
                            ref={arrowRef}
                            className={animateArrow ? styles.arrowAnimate : 'false'}
                            src="/img/home/hex-arrow.svg"
                        />
                    </h2>
                </div>
                <div className={styles.hiwStep}>
                    {/* <div>Video Here</div> */}
                    <img src="/img/home/steps/resell.png" />
                    <div>
                        <h3>Gift your customers a resell button</h3>
                        <ul>
                            <li>
                                Customers can resell preloved items in 60 seconds on your brand
                                website
                            </li>
                            <li>Purchasing a used item reduces it's carbon footprint by 82%</li>
                            <li>Integrate with your existing loyalty and rewards program</li>
                            <li>55% of sellers opt for store credit</li>
                        </ul>
                    </div>
                </div>
                <div className={styles.hiwStep}>
                    <div>
                        <h3>Hands free technology and operations.</h3>
                        <ul>
                            <li>
                                Install our app and get started in{' '}
                                <span style={{ textDecoration: 'line-through' }}>months</span> days
                            </li>
                            <li>We handle all customer support, fraud and logistics</li>
                            <li>Integrate with existing ERP systems for uninterruped operations</li>
                        </ul>
                    </div>
                    <img src="/img/home/steps/hands-free.png" />
                    {/* <div>Video Here</div> */}
                </div>
                <div className={styles.hiwStep}>
                    <img src="/img/home/steps/rescue.png" />
                    {/* <div>Video Here</div> */}
                    <div>
                        <h3>Your warehouse can list damaged inventory</h3>
                        <ul>
                            <li>Reduce wastage of inventory with minor defects</li>
                            <li>Improve your profit margins</li>
                            <li>Empty out precious warehouse space</li>
                        </ul>
                    </div>
                </div>
                <div className={styles.hiwStep}>
                    <div>
                        <h3>Both, brands and sellers earn while making the earth greener.</h3>
                        <ul>
                            <li>
                                75% of customers who bought preloved, came back to buy first hand
                            </li>
                            <li>Build brand loyalty through store credit</li>
                            <li>Generate 12-22x ROAS from store credit</li>
                        </ul>
                        *Read our case studies to learn more!
                    </div>
                    <img src="/img/home/steps/greener.png" />
                    {/* <div>Video Here</div> */}
                </div>
            </div>

            {/* Benefits with Rewards */}
            <div
                id="benefitsSection"
                className={styles.benefitsContainer + ' homePageCommonContainer'}
            >
                <div
                    style={{
                        marginTop: '15%',
                        marginBottom: '10%',
                        textAlign: 'center',
                        position: 'relative',
                        zIndex: 1
                    }}
                >
                    <h2 style={{ whiteSpace: 'nowrap' }}>A return policy is not enough</h2>
                    <h2
                        style={{
                            color: '#4e7f5e',
                            marginLeft: '15%',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        you need a resale policy
                    </h2>
                </div>

                {/* <div className={styles.quoteContainer}>
                    <img className={styles.quoteImg} src="/img/re-quote.svg" />
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "5%",
                            width: "200px",
                        }}
                    >
                        <div>
                            “Relove helps customers stay loyal to brands. We
                            have seen our customers appreciate our brand even
                            more after launching Relove.”
                        </div>
                        <img src="" />
                        <div className={styles.founderName}>
                            Meghna, Founder
                        </div>
                    </div>

                    <img className={styles.founderImg} src="" />
                    <h3>Increase your customer repeat rate by 45%.</h3>
                </div>

                <div className={styles.quoteContainer}>
                    <img className={styles.quoteImg} src="/img/re-quote.svg" />
                    <div
                        style={{
                            position: "absolute",
                            top: "20%",
                            right: "5%",
                            width: "200px",
                        }}
                    >
                        <div>
                            “Relove helps customers stay loyal to brands. We
                            have seen our customers appreciate our brand even
                            more after launching Relove.”
                        </div>
                        <img src="" />
                        <div className={styles.founderName}>
                            Meghna, Founder
                        </div>
                    </div>

                    <img className={styles.founderImg} src="" />
                    <h3>Increase your customer repeat rate by 45%.</h3>
                </div> */}

                <div className={styles.benefitsItem}>
                    <h4>1</h4>
                    <h3>Increase Brand Value</h3>
                    <div className={styles.benefitsItemCardHeader}>
                        The Summer House achieved 22x ROAS through Relove store credit
                        <br />
                    </div>
                    <div className={styles.benefitsItemCard}>
                        <div>
                            <div>
                                “Relove helps customers stay loyal to brands. We have seen our
                                customers appreciate our brand even more after launching Relove.”
                            </div>
                            <br />
                            <div className={styles.founderName}>Shivangini</div>
                            <div className={styles.founderName}>Founder, The Summer House</div>
                        </div>
                        <img
                            className={styles.founderImg}
                            src="/img/home/founders/shivangini.png"
                        />
                    </div>
                </div>

                <div className={styles.benefitsItem}>
                    <h4>2</h4>
                    <h3>Encourage Sales</h3>
                    <div className={styles.benefitsItemCardHeader}>
                        75% customers who bought preloved on Suta, came back to buy first hand.
                        <br />
                    </div>
                    <div className={styles.benefitsItemCard}>
                        <div>
                            <div>
                                “Relove has helped us acquire new customers - people who are aware
                                that buying preloved can slow down climate change”
                            </div>
                            <br />
                            <div className={styles.founderName}>Sujata and Taniya</div>
                            <div className={styles.founderName}>Co-Founders, Suta</div>
                        </div>
                        <img className={styles.founderImg} src="/img/home/founders/suta.png" />
                    </div>
                </div>

                <div className={styles.benefitsItem}>
                    <h4>3</h4>
                    <h3>More Sales</h3>
                    <div className={styles.benefitsItemCardHeader}>
                        Snitch acquired 8.4x ROAS while saving the planet
                        <br />
                    </div>
                    <div className={styles.benefitsItemCard}>
                        <div>
                            <div>“More power to Relove for providing such a platform!”</div>
                            <br />
                            <div className={styles.founderName}>Siddharth & Chetan</div>
                            <div className={styles.founderName}>Snitch</div>
                        </div>
                        <img className={styles.founderImg} src="/img/home/founders/r.png" />
                    </div>
                </div>

                <div className={styles.benefitsItem}>
                    <h4>4</h4>
                    <h3>Engage New Customers</h3>
                    <div className={styles.benefitsItemCardHeader}>
                        Of all the customers acquired through the Summer Somewhere Relove Program,
                        77% were brand new.
                        <br />
                    </div>
                    <div className={styles.benefitsItemCard}>
                        <div>
                            <div>
                                “Sustainability is one of the most important things to me as a
                                fashion founder. Launching Relove on my store was a natural
                                progression for our business to stay ahead of our sustainability
                                goals.”
                            </div>
                            <br />
                            <div className={styles.founderName}>Meghna Goyal</div>
                            <div className={styles.founderName}>Founder, Summer Somewhere</div>
                        </div>
                        <img className={styles.founderImg} src="/img/home/founders/meghna.png" />
                    </div>
                </div>

                <div className={styles.benefitsItem}>
                    <h4>5</h4>
                    <h3>Build Customer Loyalty</h3>
                    <div className={styles.benefitsItemCardHeader}>
                        Bunaai gains deep customer loyalty with 12.15x on Relove store credit
                        <br />
                    </div>
                    <div className={styles.benefitsItemCard}>
                        <div>
                            <div>
                                “Giving my customers a resell button has brought them closer to the
                                brand. Solving customers problems builds deep level of loyalty.”
                            </div>
                            <br />
                            <div className={styles.founderName}>Pari Chowdhary</div>
                            <div className={styles.founderName}>Founder, Bunaai</div>
                        </div>
                        <img className={styles.founderImg} src="/img/home/founders/pari.png" />
                    </div>
                </div>
            </div>

            {/* Sustainability Impact */}
            <div className={styles.sustainabilityContainer + ' homePageCommonContainer'}>
                <h3>Sustain customers. Sustain profits. Sustain the planet.</h3>
                <br />
                <div>
                    {/* <a
                        target="_blank"
                        href="https://reports.fashionforgood.com/report/state-of-the-circular-innovations-in-the-indian-fashion-and-textile-industry/chapterdetail?reportid=181&chapter=7#:~:text=Our%20research%20has%20shown%20that,landfills%20rather%20than%20with%20recyclers."
                    >
                        ⅓ of India’s municipal solid waste is textile.
                    </a>{' '} */}
                    Enabling a Resell button empowers your customers to reduce their water, waste
                    and carbon footprint. Adopting circularity for your brand helps you shift the
                    idea of sustainability from a burden to a growth channel.{' '}
                </div>
                <br />
                <br />

                <div>Everytime a garment is resold - </div>

                <div className={styles.sustainabilityDetails}>
                    <div>
                        <div>
                            <br />
                            <h4>6X</h4>
                            <div>The Products weight in CO2 is eliminated</div>
                            <hr />
                        </div>

                        <div>
                            <br />
                            <h4>82%</h4>
                            <div>Of the garments footprint is reduced</div>
                            <hr />
                        </div>

                        <div>
                            <br />
                            <h4>3000+</h4>
                            <div>Litres of water is saved</div>
                            <hr />
                        </div>
                    </div>
                    <img
                        className={styles.sustainImage}
                        src="/img/home/illustrations/sustain.png"
                    />
                </div>
            </div>

            {/* Reviews/Influencers */}
            <div className={styles.reviewContainer + ' homePageCommonContainer'}>
                <img src="img/home/heart.svg" />
                <h2 style={{ maxWidth: '700px' }}>
                    Second-hand shopping is becoming the first choice of shoppers
                </h2>
                <br />
                <br />
                <br />
                <SectionCarousel>
                    <div className={styles.reviewCard}>
                        <img src="/img/home/customers/08 Yosha Gupta Bunaai-01.jpeg" />
                        <div>
                            <div>
                                “Starting my personal sustainability journey slowly but surely - and
                                Relove is such a brilliant platform to enable this for me as a
                                consumer. Love how easy it is to go on the websites of the brands
                                that have enabled relove and buy from their reloved collections. The
                                notes from the exes have my• Just got my Bunaai × Relove order and
                                it is in mint condition, cannot wait to see more of my favorite
                                Indian brands be powered by Relove!.”
                            </div>
                            <br />
                            <div>Yosha Gupta</div>
                            <div>@yosh2012</div>
                        </div>
                    </div>

                    <div className={styles.reviewCard}>
                        <img src="/img/home/customers/04 Parmeshwari-01.jpeg" />
                        <div>
                            <div>
                                “Hi! Just wanted to drop a message that I absolutely love what
                                Relove is doing! It's solving for a huge problem but what I really
                                appreciate is the model. It seems like something brands would want
                                to embrace not just out of charity but because it makes business
                                sense. And like it or not, that's the only way of bringing
                                sustainable change. All my love and support, hoping to get myself a
                                Relove product soon! :)”
                            </div>
                            <br />
                            <div>Parmeshwari</div>
                            <div>@parmeshwarisartdump</div>
                        </div>
                    </div>

                    <div className={styles.reviewCard}>
                        <img src="/img/home/customers/06 Rishika Jodi-01.jpeg" />
                        <div>
                            <div>
                                “@relove_in thank you for your efforts. Got myself a @thejodilife
                                dress that I had been eyeing and it was almost brand new. We need to
                                move past thinking that every summer needs a new summer wardrobe!”
                            </div>
                            <br />
                            <div>Rishika Das Roy</div>
                            <div>@themillennialsguidetothegalaxy</div>
                        </div>
                    </div>

                    <div className={styles.reviewCard}>
                        <img src="/img/home/customers/10 Yolekha The Summer House.jpeg" />
                        <div>
                            <div>
                                “Aloha from Hawaii in my beautiful, reloved Summerhouse dress. I
                                really love wearing it and wish I could order more Relove stuff!
                                Hopefully when I come to India this year. Super impressed with the
                                ease of buying and delivery. My parents were leaving for the US and
                                there had been a delay in delivery. Prateek helped coordinate with
                                the seller and the dress arrived in time. Also impressed and excited
                                at how fast the movement is spreading amongst Indian brands. More
                                power to you guys! Would love to chat about how I can help over
                                here!”
                            </div>
                            <br />
                            <div>Yolekha Mallier</div>
                            <div>@yoleha</div>
                        </div>
                    </div>

                    <div className={styles.reviewCard}>
                        <img src="/img/home/customers/Sucharita Ghosh Okhai.png" />
                        <div>
                            <div>
                                “I just bought one beautiful dress from the Okhai Relove section,
                                and also sold one of my dresses to be reloved! I wanted to thank you
                                again for starting this initiative.What a great way to help save the
                                environment & not compromise on fashion!!! :) I wanted to let you
                                know. The whole re-love initiative has inspired me. ”
                            </div>
                            <br />
                            <div>Sucharita Ghosh</div>
                            <div>@sucharitag</div>
                        </div>
                    </div>

                    <div className={styles.reviewCard}>
                        <img src="/img/home/customers/02 Neema Jayadas The Summer House.jpg" />
                        <div>
                            <div>
                                “So happy to see the brand with Relove! You know, one aspect of
                                ReLove i love is also how it's making brands more accessible to
                                those for whom the prices could be prohibitive.”
                            </div>
                            <br />
                            <div>Neema Jayadas</div>
                            <div>@neemoticon</div>
                        </div>
                    </div>

                    <div className={styles.reviewCard}>
                        <img src="/img/home/customers/01 Aakansha Suta.jpg" />
                        <div>
                            <div>
                                “Decided to not shop for new clothes for the festivities. Instead
                                shopped circular in my endeavour to reduce my carbon footprint and
                                pass on a greener planet to our next generation" 🌎✨Bought this
                                reloved saree (@relove_in ) from @suta_bombay and rang into
                                Mahasaptami just fine" ☺️🎉 Dugga Dugga! 🙏”
                            </div>
                            <br />
                            <div>Aakanksha</div>
                            <div>@kraantinari</div>
                        </div>
                    </div>

                    <div className={styles.reviewCard}>
                        <img src="/img/home/customers/09 Prerna Sundari Handmade.jpg" />
                        <div>
                            <div>
                                “As for this look, Pinktober meets #secondhandoctober! The sari is
                                Matka silk with the most gorgeous sequins spread all over. From
                                @sundarii_handmade through @relove_in. And the blouse was a piece I
                                took from my mother’s closet. It’s my go-to for fancy occasions. If
                                I had to build a capsule wardrobe, this blouse would go in first.
                                And that’s the thing, your capsule wardrobe doesn’t have to be
                                basic. It has to be basically you”
                            </div>
                            <br />
                            <div>Prerna</div>
                            <div>@looneypenny</div>
                        </div>
                    </div>

                    <div className={styles.reviewCard}>
                        <img src="/img/home/customers/07 Sanjana Summer Somewhwere.jpg" />
                        <div>
                            <div>
                                “Wearing this beautiful secondhand @summersomewhereshop top that I
                                got through their @relove_in section. I’m so grateful to my idol and
                                friend @raising_relove_restoring_earth for creating ReLove!! It
                                allows all your favourite local brands to create a resale platform
                                that in turn gives existing customers and the brand access to second
                                hand buyers like me.”
                            </div>
                            <br />
                            <div>Sanjana Rishi</div>
                            <div>@sanjrishi</div>
                        </div>
                    </div>
                </SectionCarousel>
            </div>

            {/* Shop Now */}
            {/* Fetch details from similar /shop index js and reuse the card components */}
            {/* <ShopHome formattedListings={formattedListings} /> */}

            {/* Press */}
            <div className={styles.prContainer + ' homePageCommonContainer'}>
                <img className={styles.newsImage} src="/img/home/illustrations/news-section.png" />
                <div className={styles.prQuote}>
                    <div>
                        “Technology startup Relove aims to transform the circular fashion economy in
                        India, enabling customers to resell clothing through brand websites.”
                    </div>
                    <a
                        target="_blank"
                        href="https://thevoiceoffashion.com/sustainability/responsible-fashion/tvof-exclusive-a-relove-letter-to-secondhand-fashion--4726"
                    >
                        <img src="/img/home/pr/vof.svg" />
                    </a>
                    {/* <button
                        target="_blank"
                        className={buttonStyles.secondaryButtonSmall}
                        >
                        Read More
                    </button> */}
                </div>

                <div className={styles.prQuote}>
                    <div>
                        “Relove was born out of this need to ensure authenticity and close the gap
                        between thrifting and fashion labels”
                    </div>
                    <a
                        target="_blank"
                        href="https://www.grazia.co.in/fashion/an-ode-to-icons-and-preloved-clothing-the-summer-house-on-mindful-fashion-8406.html"
                    >
                        <img src="/img/home/pr/grazia.png" />
                    </a>
                    {/* <button
                        target="_blank"
                        className={buttonStyles.secondaryButtonSmall}
                    >
                        Read More
                    </button> */}
                </div>

                <div className={styles.prQuote}>
                    <div>“How these green techs are waging a war on the waste crisis”</div>
                    <a
                        target="_blank"
                        href="https://timesofindia.indiatimes.com/life-style/fashion/buzz/indian-fashion-brands-gear-up-for-upcycling/articleshow/92499138.cms"
                    >
                        <img style={{ height: '5rem' }} src="/img/home/pr/times_techies.png" />
                    </a>
                    {/* <button target="_blank" href="" className={buttonStyles.secondaryButtonSmall}>Read More</button> */}
                </div>

                {/* <div className={styles.prQuote}>
                    <div>“Relove is building authenticity into Resale”</div>
                    <img src="/img/home/pr/monga_bay.png" />
                    <button
                        target="_blank"
                        href="https://india.mongabay.com/2022/08/rethinking-recycling-and-re-loving-a-peek-into-indias-circular-fashion-economy/"
                        className={buttonStyles.secondaryButtonSmall}
                    >
                        Read More
                    </button>
                </div> */}
                <div className={styles.prQuote}>
                    <div>“Technology will change how we create, sell and consume fashion”</div>
                    <a
                        target="_blank"
                        href="https://m.economictimes.com/industry/cons-products/fashion-/-cosmetics-/-jewellery/from-digital-clothes-to-algo-powered-trends-technology-will-change-how-we-create-sell-and-consume-fashion/amp_articleshow/88637413.cms"
                    >
                        <img src="/img/home/pr/economic_times.png" />
                    </a>
                    {/* <button
                        target="_blank"
                        className={buttonStyles.secondaryButtonSmall}
                    >
                        Read More
                    </button> */}
                </div>
                <div className={styles.prQuote}>
                    <div>
                        “One of the celebrated sustainable brands The Summer House has a platform
                        (Relove) where customers can buy and sell verified pre-owned (pre-loved)
                        garments purchased from The Summer House”
                    </div>
                    <a target="_blank">
                        <img style={{ height: '5rem' }} src="/img/home/pr/toi.png" />
                    </a>
                    {/* <button
                        target="_blank"
                        href=""
                        className={buttonStyles.secondaryButtonSmall}
                    >
                        Read More
                    </button> */}
                </div>
                {/* <div className={styles.prQuote}>
                    <div>
                        “A Resale Platform Transforming Circular Fashion In
                        India”
                    </div>
                    <img src="/img/home/pr/monga_bay.png" />
                    <button
                        target="_blank"
                        href="https://india.mongabay.com/2022/08/rethinking-recycling-and-re-loving-a-peek-into-indias-circular-fashion-economy/"
                        className={buttonStyles.secondaryButtonSmall}
                    >
                        Read More
                    </button>
                </div> */}

                <div className={styles.logoContainer}>
                    <div>
                        <a
                            target="_blank"
                            href="https://startup.google.com/accelerator/circular-economy/"
                        >
                            <img src="/img/home/pr/Google-for-Startups-Accelerator-Logo-300x120.png" />
                        </a>
                    </div>
                    <div>
                        <a target="_blank" href="https://dainik-b.in/1JNyXpiGotb">
                            <img src="/img/home/pr/Dainik_Bhaskar_Logo.png" />
                        </a>
                    </div>
                    <div>
                        <img src="/img/home/pr/Doordarshan_Logo_(1).png" />
                    </div>
                    <div>
                        <a
                            target="_blank"
                            href="https://zeezest.com/style-beauty/are-pre-loved-goods-the-future-of-sustainable-fashion-2423"
                        >
                            <img src="/img/home/pr/Zee_Zest.webp" />
                        </a>
                    </div>
                    <div>
                        <a
                            target="_blank"
                            href="https://www.thehansindia.com/featured/womenia/samantha-ruth-prabhu-sushruthi-krishna-join-hands-for-responsible-fashion-760190"
                        >
                            <img src="/img/home/pr/The Hans India .png" />
                        </a>
                    </div>
                    <div>
                        <a
                            target="_blank"
                            href="https://www.theestablished.com/community/conscious-living/rescue-by-relove-is-doing-away-with-the-idea-of-reject-clothing-to-make-minor-defects-covetable?fbclid=IwAR2dYhc-HHre3T6BnDAfjV4Amur3pt2CMIV6jP8SnQ2qRQNrIWojDG9jhKM"
                        >
                            <img src="/img/home/pr/The Established .png" />
                        </a>
                    </div>
                    <div>
                        <img src="/img/home/pr/scroll-default.d78c35dfafcc7b30a9b371ecf515075f.003.png" />
                    </div>
                    <div>
                        <a
                            target="_blank"
                            href="https://www.news9live.com/lifestyle/with-okhai-relove-and-restore-kirti-poonia-is-changing-the-way-we-consume-169450"
                        >
                            <img src="/img/home/pr/News9live-logo_1_300x.png" />
                        </a>
                    </div>
                    <div>
                        <img src="/img/home/pr/cnbc.png" />
                    </div>
                    <div>
                        <a target="_blank" href="https://www.instagram.com/p/CjuWhUbDc-E/">
                            <img src="/img/home/pr/Lakmé_Fashion_Week_(2021).svg.png" />
                        </a>
                    </div>
                    <div>
                        <a
                            target="_blank"
                            href="https://www.platform-mag.com/fashion/relove-summer-somewhere.html"
                        >
                            <img src="/img/home/pr/Platform Mag.png" />
                        </a>
                    </div>
                </div>
            </div>

            <div />
            {/* Case Studies */}
            {/* Need a way to collect the Emails for the case studies. 
                This MIGHT be merged with the HIW Section. */}

            {/* FAQs */}
            {/* This content can be same as current FAQs, we can reformat it for both places to reuse the components */}
            <div className={styles.faqContainer + ' homePageCommonContainer'}>
                <div className={styles.faqFlexContainer}>
                    <div style={{ marginBottom: '50px' }}>
                        <h2>Frequently Asked Questions </h2>
                        <p>Everybody has questions, we're here to answer them.</p>
                    </div>
                    <img src="/img/home/illustrations/faq.png" alt="faq" />
                </div>
                <Accordion>
                    <Accordion.Item eventKey="001">
                        <Accordion.Header>
                            <div className={styles.heading}>For Brands</div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <FaqMerchant />
                        </Accordion.Body>
                    </Accordion.Item>
                    <div className={styles.horizontalRule}></div>
                    <Accordion.Item eventKey="002">
                        <Accordion.Header>
                            <div className={styles.heading}>For Sellers</div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <FaqSeller />
                        </Accordion.Body>
                    </Accordion.Item>
                    <div className={styles.horizontalRule}></div>
                    <Accordion.Item eventKey="003">
                        <Accordion.Header>
                            <div className={styles.heading}>For Buyers</div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <FaqBuyer />
                        </Accordion.Body>
                    </Accordion.Item>
                    <div className={styles.horizontalRule}></div>
                </Accordion>
            </div>
        </div>
    );
};

Index.getLayout = function getLayout(index) {
    return (
        <>
            <Meta />
            <script
                charSet="utf-8"
                type="text/javascript"
                src="//js.hsforms.net/forms/embed/v2.js"
            ></script>
            <ReloveHeader showDemo />
            {index}
            <a
                href={CHAT_SUPPORT}
                style={{
                    position: 'fixed',
                    width: '60px',
                    height: '60px',
                    bottom: '40px',
                    right: '40px',
                    backgroundColor: '#25d366',
                    color: 'white',
                    borderRadius: '50px',
                    textAlign: 'center',
                    fontSize: '30px',
                    boxShadow: '2px 2px 3px #999',
                    zIndex: '200'
                }}
                target="_blank"
            >
                <svg
                    style={{ marginTop: '13px' }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-whatsapp"
                    viewBox="0 0 16 16"
                >
                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                </svg>
            </a>
            <ReloveFooter />
        </>
    );
};

export default Index;

export async function getStaticProps() {
    const sizeMapping = await prisma.generic_size_mapping.findMany();
    let formattedListings = await fetch_listings(false, sizeMapping);

    if (formattedListings.length == 0) {
        throw new Error('Unable to generate new listings, using old ones');
    }

    return {
        props: {
            formattedListings: formattedListings.slice(0, 6)
            // sizeMapping,
        },
        // Next.js will attempt to re-generate the page:
        // - When a request comes in
        // - At most once every x seconds
        revalidate: 60 * 120 // In seconds
    };
}
