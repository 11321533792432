import StyledToggleButton from "components/sell/ToggleButton/StyledToggleButton";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import styles from "./SectionCarousel.module.css";

const SectionCarousel = (props) => {
    const leftRef = useRef(null);
    const [count, setCount] = useState(0);
    const [showArrow, setShowArrow] = useState({ left: false, right: true });
    const rightRef = useRef(null);
    const containerRef = useRef();

    useEffect(() => {
        const leftObserver = new window.IntersectionObserver(
            (e) => {
                if (e[0].isIntersecting == 1) {
                    setCount(0);
                    setShowArrow((prev) => ({ right: true, left: false }));
                } else setShowArrow((prev) => ({ ...prev, left: true }));
            },
            { root: containerRef.current, threshold: 0 }
        );
        const rightObserver = new window.IntersectionObserver(
            (e) => {
                if (e[0].isIntersecting == 1)
                    setShowArrow((prev) => ({ left: true, right: false }));
                else setShowArrow((prev) => ({ ...prev, right: true }));
            },
            { root: containerRef.current, threshold: 0 }
        );
        leftRef.current && leftObserver.observe(leftRef.current);
        rightRef.current && rightObserver.observe(rightRef.current);
        return () => {
            leftObserver.disconnect(leftRef.current);
            rightObserver.disconnect(rightRef.current);
        };
    }, []);

    return (
        <div ref={containerRef} className={styles.scrollContainer}>
            <div
                ref={leftRef}
                style={{
                    position: "absolute",
                    height: "100%",
                    width: "5rem",
                    marginLeft: "20%",
                    left: 0,
                    top: 0,
                    zIndex: -1,
                }}
            ></div>
            {containerRef.current?.scrollWidth >
                containerRef.current?.clientWidth && (
                <div
                    onClick={() => {
                        containerRef.current.scroll(
                            (count - 1) * containerRef.current.clientWidth,
                            0
                        );
                        setCount((count) => count - 1);
                    }}
                    style={{
                        left: "-2%",
                        top: "50%",
                        transform: "translateY(-50%)",
                    }}
                    className={
                        styles.arrow + " " + (!showArrow.left && styles.none)
                    }
                >
                    <img
                        style={{
                            width: "1.5rem",
                            objectFit: "contain",
                            rotate: "180deg",
                        }}
                        src="/img/home/green-arrow.svg"
                    />
                </div>
            )}
            {props.children}

            {containerRef.current?.scrollWidth >
                containerRef.current?.clientWidth && (
                <div
                    onClick={() => {
                        containerRef.current.scroll(
                            (count + 1) * containerRef.current.clientWidth,
                            0
                        );
                        setCount((count) => count + 1);
                    }}
                    style={{
                        right: "-2%",
                        top: "50%",
                        transform: "translateY(-50%)",
                    }}
                    className={
                        styles.arrow + " " + (!showArrow.right && styles.none)
                    }
                >
                    <img
                        style={{ width: "1.5rem", objectFit: "contain" }}
                        src="/img/home/green-arrow.svg"
                    />
                </div>
            )}
            <div
                ref={rightRef}
                style={{
                    position: "relative",
                    height: "100%",
                    width: "5rem",
                    marginLeft: "-15%",
                    right: 0,
                    top: 0,
                }}
            ></div>
        </div>
    );
};

export default SectionCarousel;
