import React from 'react';
import styles from './HexImages.module.css';

const HexImages = () => {
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <img
                    style={{
                        height: `55.9rem`,
                        top: 178,
                        left: 213
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-sanjana.png"
                />
                <img
                    style={{
                        height: `31rem`,
                        top: 542,
                        left: 958
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-gayatri.png"
                />
                <img
                    style={{
                        height: `34.42rem`,
                        top: 54,
                        left: 1180
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-priya.png"
                />
                <img
                    style={{
                        height: `36.5rem`,
                        top: 54,
                        left: 1703
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-yolekha.png"
                />
                <img
                    style={{
                        height: `53.5rem`,
                        top: 542,
                        left: 1326
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-anupriya-2.png"
                />
                <img
                    style={{
                        height: `31.18rem`,
                        top: 1144,
                        left: 273
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-sustainibili.png"
                />
                <img
                    style={{
                        height: `49rem`,
                        top: 970,
                        left: 688
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-sanjana-infant.png"
                />
                <img
                    style={{
                        height: `37.6rem`,
                        top: 1365,
                        left: 1263
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-poojitha.png"
                />
                <img
                    style={{
                        height: `41.9rem`,
                        top: 1220,
                        left: 1835
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-aakansha.png"
                />
                <img
                    style={{
                        height: `41.6rem`,
                        top: 1636,
                        left: 79
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-yolekha.png"
                />
                <img
                    style={{
                        height: `43.5rem`,
                        top: 1712,
                        left: 572
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-yosha.png"
                />
                <img
                    style={{
                        height: `40.6rem`,
                        top: 1948,
                        left: 1063
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-urban-dharma.png"
                />
                <img
                    style={{
                        height: `57.68rem`,
                        top: 1835,
                        left: 1549
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-poomina-2.png"
                />
                <img
                    style={{
                        height: `54.8rem`,
                        top: 2297,
                        left: 187
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-rishika.png"
                />
                <img
                    style={{
                        height: `45.25rem`,
                        top: 2605,
                        left: 827
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-neema.png"
                />

                <img
                    style={{
                        height: `41.6rem`,
                        top: 2664,
                        left: 1369
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-prerna.png"
                />
                {/* TODO Some white space towards the end fill it up */}

                {/* THIS SECTION REPEATS */}
                <img
                    style={{
                        height: `55.9rem`,
                        top: 3398,
                        left: 213
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-sanjana.png"
                />
                <img
                    style={{
                        height: `31rem`,
                        top: 3762,
                        left: 958
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-gayatri.png"
                />
                <img
                    style={{
                        height: `34.42rem`,
                        top: 3274,
                        left: 1180
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-priya.png"
                />
                <img
                    style={{
                        height: `36.5rem`,
                        top: 3274,
                        left: 1703
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-yolekha.png"
                />
                <img
                    style={{
                        height: `53.5rem`,
                        top: 3762,
                        left: 1326
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-anupriya-2.png"
                />
                <img
                    style={{
                        height: `31.18rem`,
                        top: 4364,
                        left: 273
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-sustainibili.png"
                />
                <img
                    style={{
                        height: `49rem`,
                        top: 4190,
                        left: 688
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-sanjana-infant.png"
                />
                <img
                    style={{
                        height: `37.6rem`,
                        top: 4585,
                        left: 1263
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-poojitha.png"
                />
                <img
                    style={{
                        height: `41.9rem`,
                        top: 4440,
                        left: 1835
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-aakansha.png"
                />
                <img
                    style={{
                        height: `41.6rem`,
                        top: 4856,
                        left: 79
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-yolekha.png"
                />
                <img
                    style={{
                        height: `43.5rem`,
                        top: 4932,
                        left: 572
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-yosha.png"
                />
                <img
                    style={{
                        height: `40.6rem`,
                        top: 5168,
                        left: 1063
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-urban-dharma.png"
                />
                <img
                    style={{
                        height: `57.68rem`,
                        top: 5055,
                        left: 1549
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-poomina-2.png"
                />

                {/* <img
                    style={{
                        height: `28.4rem`,
                        top: 2496,
                        left: 1113,
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-yolekha.png"
                />
                <img
                    style={{
                        height: `31rem`,
                        top: 3262,
                        left: 206,
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-sustainibili.png"
                />
                <img
                    style={{
                        height: `51rem`,
                        top: 2895,
                        left: 622,
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-prachi+(1).png"
                />
                <img
                    style={{
                        height: `53.25rem`,
                        top: 2972,
                        left: 1290,
                    }}
                    src="https://d2u551lsy62yzf.cloudfront.net/hex-image-anupriya.png"
                /> */}
            </div>
        </div>
    );
};

export default HexImages;
