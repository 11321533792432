import styles from './ReloveFooter.module.css';

const ReloveFooter = ({}) => {
    return (
        <div className={styles.footer + ' homePageCommonContainer'}>
            <div>
                <h3>Company</h3>
                <div>
                    <a href="/return-policy" target="_blank">
                        Relove Guarantee
                    </a>
                </div>
                <div>
                    <a href="https://blog.relove.in/news-you-can-reuse" target="_blank">
                        Blog
                    </a>
                </div>
                <div>
                    <a href="https://angel.co/company/relove/jobs" target="_blank">
                        Careers
                    </a>
                </div>
                <div>
                    <a href="mailto:contact@relove.in">Investors</a>
                </div>
                <div>
                    <a href="/shop" target="_blank">
                        Shop
                    </a>
                </div>
            </div>
            <div>
                <h3>Tools</h3>
                <div>
                    <a href="/order-status/track-your-order" target="_blank">
                        Track My Order
                    </a>
                </div>
                <div>
                    <a href="/privacy-policy" target="_blank">
                        Privacy Policy
                    </a>
                </div>
                <div>
                    <a href="/return-policy" target="_blank">
                        Returns and Refunds
                    </a>
                </div>
                <div>
                    <a href="/terms-conditions" target="_blank">
                        Terms and Conditions
                    </a>
                </div>
                <div>
                    <a href="mailto:contact@relove.in">Support</a>
                </div>
                <div>
                    <a href="/faq" target="_blank">
                        FAQ
                    </a>
                </div>
                <div>
                    <a href="mailto:contact@relove.in">Contact Us</a>
                </div>
            </div>
            <div>
                <h3>Social</h3>
                <div>
                    <a href="https://www.linkedin.com/company/relove-in" target="_blank">
                        Linkedin
                    </a>
                </div>
                <div>
                    <a href="https://www.instagram.com/relove_in/" target="_blank">
                        Instagram
                    </a>
                </div>
            </div>
            <div>
                <img src="/relove-logo-white.png" />
                <br />
                <br />
                <div>Bahaal Technologies Pvt Ltd</div>
                <div>143, Rajdeep, Lt. Dilip Gupte Marg, Mahim, Mumbai 400016</div>
                <div>contact@relove.in</div>
                <div>WhatsApp: +91 93210 50103</div>
            </div>
        </div>
    );
};

export default ReloveFooter;
