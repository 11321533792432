import { ToggleButton } from "react-bootstrap";
import styles from "./ToggleButton.module.css";

const StyledToggleButton = ({ id, name, displayName, handleChange, value, checked }) => {
    return (
        <ToggleButton
            id={id}
            name={name}
            onChange={handleChange}
            className={
                "mb-2 " + styles.toggleButton + " " + (checked ? styles.toggleButtonSelected : "")
            }
            type="checkbox"
            variant="outline-primary"
            value={value}
            title={displayName}
            checked={checked}
        >
            {displayName}
        </ToggleButton>
    );
};

export default StyledToggleButton;
