import styles from './ReloveHeader.module.css';
import * as buttonStyles from 'components/buttons/Button.module.css';

const ReloveHeader = ({}) => {
    return (
        <nav className={styles.navbar + ' homePageCommonContainer'}>
            <ul>
                <li>
                    <a href="#">
                        <img height="65" src="/relove-logo.png" />
                    </a>
                </li>
                <li className={styles.hide_sm}>
                    <a href="#hiwSection">How it Works</a>
                </li>
                <li className={styles.hide_sm}>
                    <a href="#benefitsSection">Benefits</a>
                </li>
                <li>
                    <a href="/account">Sell</a>
                </li>
                <li>
                    <button
                        type="button"
                        onClick={() => window.open('/shop', '_blank')}
                        className={buttonStyles.primaryButton}
                    >
                        Shop
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default ReloveHeader;
