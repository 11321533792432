import { Accordion } from 'react-bootstrap';
import styles from './Faq.module.css';

const FaqBuyer = ({ page }) => {
    return (
        <Accordion className="mb-5">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        Are these genuine products?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        Only items directly purchased from brands are listed for sale. These items
                        are verified through past orders and confirmed through images and videos.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        What is the return policy?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        Relove transactions are not reversible. Relove does not offer returns to the
                        buyer or seller for reloved products, unless the product does not match the
                        condition listed.
                        <br />
                        Read our return policy{' '}
                        <a href="/return-policy" target="_blank">
                            here
                        </a>
                        .
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        What if the item is damaged?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        If you receive an item with damages or in a condition different than
                        specified by the seller, let us know within 48 hrs and we will investigate
                        the matter and issue a refund.
                        <br />
                        Read our return policy{' '}
                        <a href="/return-policy" target="_blank">
                            here
                        </a>
                        .
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        How long does shipping take?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        Shipping reloved items can take longer than regular products as the item is
                        shipped directly from the seller and is verified by our team. Allow a
                        minimum of 10 to 14 days for your item to be delivered.
                        <br />
                        You will receive regular updated on the status of your item via email.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        Why should I buy second hand?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        We use a fraction of our wardrobe on a regular basis while the rest simply
                        lies unused. Yet the size of the average wardrobe has doubled over the last
                        10 years. The fashion industry contributes to 10% of global CO<sub>2</sub>{' '}
                        emissions. Thrifting allows us to make the maximum use of garments already
                        in circulation.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        What if I change my mind?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        All orders are non-reversible! Please make sure you have reviewed the item
                        and it's condition before buying.
                        <br />
                        Read our return policy{' '}
                        <a href="/return-policy" target="_blank">
                            here
                        </a>
                        .
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        Is your packaging sustainable?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        Packages to the seller containing labels and envelopes are made from
                        recycled paper. Whereas the enevelope itself is made from kraft paper that
                        is recyclable.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        What about the carbon emissions from all the shipping?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        The CO<sub>2</sub> emissions from shipping are a small fraction of the
                        emissions required to make a completely new garment. Right from extraction
                        of the raw material, the waste water while making the fabric, tailoring and
                        the eventual shipping of the new garment itself.
                        <br />
                        <br />
                        The most sustainable alternative continues to be to buy less, but if you
                        still need that new outfit, why not thrift it; You can get a great deal
                        while you're saving the planet too!
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default FaqBuyer;
