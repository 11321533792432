import { Accordion } from "react-bootstrap";
import styles from "./Faq.module.css";

const FaqSeller = (params) => {
    return (
        <Accordion className="mb-5">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <div className={styles.heading}>Who pays for shipping?</div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={styles.content}>
                        Relove bears the cost of the shipping between the seller and buyer. This
                        cost is factored into the comission charged on the sale of the item.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <div className={styles.heading}>How do you manage fraud?</div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={styles.content}>
                        Relove verifies the authenticity and condition of the item before it is
                        approved for listing. The item is verified using a combination of manual and
                        automated techniques. Additionally, the seller's payment is held in escrow
                        until the buyer confirms the state of the item.
                        <br />
                        Relove takes responsibility for any fraud and damaged item during transit.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <div className={styles.heading}>
                        What is the wallet system and why is it required?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={styles.content}>
                        Relove uses a prepaid wallet system for the relove comission - this includes
                        payments made to the seller, transportation and packaging costs. A prepaid
                        system helps ensure the seller is paid in time and there is enough balance
                        available.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <div className={styles.heading}>How are refunds handled?</div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={styles.content}>
                        Relove handles all cancellations, however refunds are handled by the
                        merchant since they are processed on their payment gateway.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>
                    <div className={styles.heading}>Can I customize the storefront?</div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={styles.content}>
                        Yes, the storefront can be fully customized depending on the merchant
                        requirements. We provide an initial standard template however this can be
                        changed as per the requirement. Reach out to as contact@relove.in for more
                        information.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>
                    <div className={styles.heading}>I want a complete whitelabelled experience</div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={styles.content}>
                        We do not currently offfer a whitelabel solution. However this may be
                        available in the future. Reach out to us at contact@relove.in to let us know
                        about your requirements.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>
                    <div className={styles.heading}>
                        How much store credit do you recommend I provide?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={styles.content}>
                        We recommend you offer store credit upto 10-15% of the value of the product
                        being sold; or calculated as a factor of your acquisition costs on other
                        channels.
                        <br />
                        Store credit is a great way to incentivize sellers to shop with you again
                        and building customer loyalty.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>
                    <div className={styles.heading}>
                        What about cannibalization of my first hand sale?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={styles.content}>
                        Relove servers have a great customer acquisition channel for brands to
                        acquire new customers that are eco-conscious. 45% of relove customers are
                        new to our brands
                        <br />
                        It is important to note that the resale market for your products already
                        exists on 3rd party websites. Relove simply allows the brand to reclaim this
                        market.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
                <Accordion.Header>
                    <div className={styles.heading}>Are offline orders available for resale?</div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={styles.content}>
                        Yes, orders made offline or gifted can also be resold. As long as the
                        required product information for the product is present.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
                <Accordion.Header>
                    <div className={styles.heading}>
                        Can I choose which items are available for resale?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={styles.content}>
                        Yes, you can decide which products or categories can be made available for
                        resale.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
                <Accordion.Header>
                    <div className={styles.heading}>Can I sell items from other brands?</div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={styles.content}>
                        No. We require the product information of the product being sold be
                        available on the store. This helps keep a consistent and high quality
                        experience for buyers, along with reduced fraud.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default FaqSeller;
